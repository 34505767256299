// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1CK7FHVwhiOnuCiZJwiPq1 {\r\n\twidth: 40px;\r\n\theight: 40px;\r\n\tmargin: 100px auto;\r\n\tbackground-color: #fff;\r\n\r\n\tborder-radius: 100%;\r\n\t-webkit-animation: _1hEQ2VzIkTiZ-9jZoLI3JC 1s infinite ease-in-out;\r\n\tanimation: _1hEQ2VzIkTiZ-9jZoLI3JC 1s infinite ease-in-out;\r\n}\r\n\r\n@-webkit-keyframes _1hEQ2VzIkTiZ-9jZoLI3JC {\r\n\t0% {\r\n\t\t-webkit-transform: scale(0);\r\n\t}\r\n\t100% {\r\n\t\t-webkit-transform: scale(1);\r\n\t\topacity: 0;\r\n\t}\r\n}\r\n\r\n@keyframes _1hEQ2VzIkTiZ-9jZoLI3JC {\r\n\t0% {\r\n\t\t-webkit-transform: scale(0);\r\n\t\ttransform: scale(0);\r\n\t}\r\n\t100% {\r\n\t\t-webkit-transform: scale(1);\r\n\t\ttransform: scale(1);\r\n\t\topacity: 0;\r\n\t}\r\n}\r\n", ""]);
// Exports
exports.locals = {
	"spinner": "_1CK7FHVwhiOnuCiZJwiPq1",
	"sk-scaleout": "_1hEQ2VzIkTiZ-9jZoLI3JC"
};
module.exports = exports;
